<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    id="one-way"
    class="w-100"
  >
    <div class="section-airport w-100 d-flex flex-wrap flex-lg-nowrap align-items-center mb-2">
      <!-- ANCHOR From -->
      <b-card
        class="flex-grow-1 mb-0 w-100 mb-1 mb-lg-0"
        body-class="p-50"
      >
        <div class="ml-1 text-subtitle">
          {{ $t('flight.startFrom') }}
        </div>
        <SearchAirportSelect :data-prop.sync="searchData.flights[0].startPoint" />
      </b-card>

      <!-- ANCHOR switch -->
      <div class="switch_airport mx-auto mx-lg-2 my-lg-1 flex-shrink-1">
        <b-button
          :variant="['lg', 'xl'].includes(appBreakPoint) ? 'white' : 'info'"
          class="btn-icon rounded-circle shadow-lg text-white rounded-circle"
          :disabled="!(searchData.flights[0].startPoint && searchData.flights[0].endPoint)"
          @click="$emit('swap-from-to')"
        >
          <IconSvg
            :src="require('@icons/swap.svg')"
            class="m-25 d-flex-center"
            :class="['lg', 'xl'].includes(appBreakPoint) ? 'text-body' : 'text-white'"
            size="20px"
          />
        </b-button>
      </div>

      <!-- ANCHOR TO -->
      <b-card
        class="flex-grow-1 mb-0 w-100"
        body-class="p-50"
      >
        <div class="ml-1 text-subtitle">
          {{ $t('flight.endAt') }}
        </div>
        <SearchAirportSelect
          :data-prop.sync="searchData.flights[0].endPoint"
          right
        />
      </b-card>
    </div>

    <b-card
      class="mb-2"
      no-body
    >
      <div class="d-flex flex-column flex-lg-row ml-3 ml-lg-0 gap-1 w-100 px-1 py-50 justify-content-lg-center align-items-lg-center">
        <!-- ANCHOR Direct flight select -->
        <SearchDirectFlightSelect
          style="min-width: 220px"
          :number-of-stop.sync="searchData.numberOfStop"
          :is-search-class-booking="searchData.isSearchClassBooking"
          class="divider-after"
        />

        <!-- ANCHOR Date -->
        <SearchDatePicker
          :date.sync="searchData.flights[0].departDate"
          class="divider-after"
          :config="{
            minDate: 'today',
          }"
          style="width: 200px"
        >
          <template #icon>
            <img
              src="@icons/calendar.svg"
              class="mr-50 width-30-per"
            >
          </template>
        </SearchDatePicker>

        <!-- ANCHOR Class Booking -->
        <div class="d-flex align-items-center">
          <SearchClassBookingSelect
            v-if="bookingData && bookingData.source.includes('VN1A') && isAddFlights"
            :is-search-class-booking.sync="searchData.isSearchClassBooking"
            :is-search-monthly-cheapest-fare="searchData.isSearchMonthlyCheapestFare"
            :is-disabled="bookingData.source.includes('VN1A')"
            class="flex-fill mr-2"
            style="max-width: 250px"
          />
        </div>
      </div>
    </b-card>

    <b-card
      class="mb-2"
      no-body
      :class="['lg', 'xl'].includes(appBreakPoint) ? '' : 'd-none'"
    >
      <div class="d-flex flex-column flex-lg-row gap-1 w-100 px-1 py-50 d-flex-center">
        <!-- ANCHOR TYPE -->
        <!-- <SearchSwitchTypeDropdown
          :type.sync="searchData.type"
          class="divider-after d-flex-center"
        /> -->

        <!-- ANCHOR Airlines -->
        <SearchSourceSelect
          style="min-width: 240px"
          :airlines.sync="searchData.airlines"
          class="divider-after"
        />

        <!-- ANCHOR Passenger -->
        <SearchPassenger
          :adult.sync="searchData.adult"
          :child.sync="searchData.child"
          :infant.sync="searchData.infant"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,

    SearchDatePicker: () => import('./components/SearchDatePicker.vue'),
    SearchClassBookingSelect: () => import('./components/SearchClassBookingSelect.vue'),
    SearchDirectFlightSelect: () => import('./components/SearchDirectFlightSelect.vue'),
    SearchAirportSelect: () => import('./components/SearchAirportSelect.vue'),
    // SearchSwitchTypeDropdown: () => import('./components/SearchSwitchTypeDropdown.vue'),
    SearchSourceSelect: () => import('./components/SearchSourceSelect.vue'),
    SearchPassenger: () => import('./components/SearchPassenger.vue'),
  },
  props: {
    searchData: {
      type: Object,
      default: () => {},
    },
    bookingData: {
      type: Object,
      default: () => {},
    },
    isAddFlights: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>

<style lang="scss" scoped>
#one-way ::v-deep {
  .divider-after {
    &::after {
      content: '';
      display: inline-block;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #d3d3d3;
      height: 28px;
      margin: 0 1rem;

      @media (max-width: 991px) {
        content: none;
      }
    }
  }
}

.section-airport {
  position: relative;
  .switch_airport {
    @media (max-width: 991px) {
      position: absolute;
      z-index: 99;
      right: -40px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
